import { useContext, useState } from "react";
import { CircularProgress, IconButton, Stack } from "@mui/material";
import { assignEntity } from "../dao/assign";
import { getType } from "../utils/utils";
import { editRecords } from "../dao/common";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import CloseIcon from "@mui/icons-material/Close";
import FeedbackContext from "../feedbackContext";
import { SEVERITY } from "./FeedbackComponent";

/*
 * This component is used to show assign entity buttons accordingly and
 * handles the requests.
 * @param {Object} props - The props object
 * @param {boolean} props.nestedEntity - A boolean to check if the entity is nested,
 * if true, it renders the button in the sub overview
 * if false it renders the button in the modal
 * @param {Object} props.options - The options object
 * @param {Array} props.options.selectedRows - The selected rows
 * @param {string} props.options.entity - The entity to be assigned
 * @param {Object} props.options.assignTo - The entity to assign to
 * @param {Function} props.options.handleAssignment - The function to handle modal open and isAssigned state
 * @returns {JSX.Element} - The JSX element
 */

export const AssignButtons = (props) => {
  const {
    nestedEntity = false,
    options: {
      selectedRows = [],
      entity = "",
      assignTo = {},
      handleAssignment = () => {},
    },
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { setFeedback } = useContext(FeedbackContext);

  const isAssignButton = selectedRows.length === 0;
  const title = `${isAssignButton ? "Assign " : "Unassign "}${entity}s`;

  const assign = async () => {
    setIsLoading(true);
    try {
      await assignEntity(entity, assignTo, selectedRows);
      handleAssignment({
        open: false,
        isAssigned: true,
      });

      setFeedback({
        isOpen: true,
        messages: [
          `Successfully assigned ${entity}s to the ${getType(
            assignTo.entityName
          )}.`,
        ],
        severity: SEVERITY.SUCCESS,
      });
    } catch (error) {
      const message =
        error?.message ||
        `There was an error while assigning ${entity}s to the ${getType(
          assignTo.entityName
        )}.`;

      setFeedback({
        isOpen: true,
        messages: [message],
        severity: SEVERITY.ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const unassign = async () => {
    try {
      setIsLoading(true);
      await editRecords({
        ids: selectedRows.map((row) => row.id),
        type: entity,
        fields: { [`${assignTo.entityName}_id`]: null },
      });
      handleAssignment({
        isAssigned: true,
      });
      setFeedback({
        isOpen: true,
        messages: [
          `Successfully unassigned ${entity}s from the ${getType(
            assignTo.entityName
          )}.`,
        ],
        severity: SEVERITY.SUCCESS,
      });
    } catch (error) {
      const message =
        error?.message ||
        `There was an error while unassigning ${entity}s from the ${getType(
          assignTo.entityName
        )}.`;
      setFeedback({
        isOpen: true,
        messages: [message],
        severity: SEVERITY.ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return nestedEntity ? (
    <IconButton
      title={title}
      loading={isLoading}
      onClick={() => {
        if (selectedRows.length > 0) {
          unassign();
        } else {
          handleAssignment({ open: true });
        }
      }}
    >
      {isAssignButton ? <InsertLinkIcon /> : <LinkOffIcon />}
    </IconButton>
  ) : (
    <Stack direction={"row"} spacing={1}>
      <IconButton
        title={`Assign ${entity}s`}
        variant='contained'
        disabled={selectedRows.length === 0}
        onClick={assign}
        loading={isLoading}
        data-testid='assign-button-in-modal'
      >
        {isLoading ? <CircularProgress size={24} /> : <InsertLinkIcon />}
      </IconButton>
      <IconButton
        title='Close modal'
        variant='contained'
        onClick={handleAssignment}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};
