import { createContext, useMemo, useState } from "react";
import { AUTOHIDEDURATION, SEVERITY } from "./components/FeedbackComponent";

const FeedbackContext = createContext();

export function FeedbackProvider({ children }) {
  const [feedback, setFeedback] = useState({
    isOpen: false,
    messages: [],
    severity: SEVERITY.INFO,
    autohideDuration: AUTOHIDEDURATION,
  });

  const feedbackData = useMemo(
    () => ({
      feedback,
      setFeedback,
    }),
    [feedback, setFeedback]
  );
  return (
    <FeedbackContext.Provider value={feedbackData}>
      {children}
    </FeedbackContext.Provider>
  );
}

export default FeedbackContext;
