import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import { stopPropagation } from "../../utils/utils";
import { DIALOG_DELETE_TOGGLE } from "../TableComponent";
import { LoadingButton } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useContext, useState } from "react";
import FeedbackContext from "../../feedbackContext";
import { SEVERITY } from "../FeedbackComponent";

const DELETE_TYPES = {
  HARD: "hard",
  SOFT: "soft",
};

export const DeleteDialog = ({
  visible = false,
  setVisible,
  softDeleteEntry,
  hardDeleteEntry,
  deleteState,
  refreshData,
  filterString,
}) => {
  const [isLoading, setIsLoading] = useState({
    [DELETE_TYPES.HARD]: false,
    [DELETE_TYPES.SOFT]: false,
  });

  const { setFeedback } = useContext(FeedbackContext);

  const handleClose = () => {
    setVisible({ type: DIALOG_DELETE_TOGGLE, payload: deleteState.entity });
  };

  const handleDelete = async (type, event) => {
    stopPropagation(event);
    const deleteConfirmation = window.confirm(
      type === DELETE_TYPES.HARD
        ? "⚠️ Are you sure you want to hard delete this entry? This action cannot be undone."
        : "Are you sure you want to soft delete this entry?"
    );
    if (!deleteConfirmation) {
      return;
    }
    try {
      setIsLoading({ ...isLoading, [type]: true });

      if (type === DELETE_TYPES.HARD) {
        await hardDeleteEntry(deleteState.entity);
      } else {
        await softDeleteEntry(deleteState.entity);
      }
      setFeedback({
        isOpen: true,
        messages: ["The request was successful"],
        severity: SEVERITY.SUCCESS,
      });
    } catch (error) {
      setFeedback({
        isOpen: true,
        messages: [error?.response?.body?.status.message],
        severity: SEVERITY.ERROR,
      });
    } finally {
      if (deleteConfirmation || type === DELETE_TYPES.SOFT) {
        setIsLoading({ ...isLoading, [type]: false });
        handleClose();
        refreshData(filterString);
      }
    }
  };

  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      aria-labelledby='hard-soft-delete-confirmation-title'
      aria-describedby='hard-soft-delete-confirmation-description'
      data-testid='deleteDialog'
    >
      <DialogTitle id='hard-soft-delete-confirmation-title'>
        Delete confirmation
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='hard-soft-delete-confirmation-description'>
          <Typography sx={{ mb: 3 }}>
            Do you want to hard delete or soft delete this entry?
          </Typography>

          {deleteState.entity && (
            <Accordion
              aria-labelledby='entity-details-summary'
              aria-describedby='entity-details-list'
              data-testid='entityDetails'
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ borderBottom: "1px solid lightgrey" }}
                id='entity-details-summary'
              >
                <Typography>Entity Details</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ maxHeight: 300, overflow: "auto" }}>
                <List id='entity-details-list'>
                  {Object.entries(deleteState.entity).map(([key, value]) => (
                    <ListItem key={key}>
                      <ListItemText primary={key} secondary={`${value}`} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack direction='row' justifyContent='space-between' width='100%'>
          <Button
            onClick={handleClose}
            data-testid='cancelButton'
            disabled={isLoading.hard || isLoading.soft}
            aria-label='cancel-button'
          >
            Cancel
          </Button>
          <Box>
            <LoadingButton
              loading={isLoading.hard}
              disabled={isLoading.soft}
              data-testid='hardDeleteButton'
              onClick={(event) => handleDelete(DELETE_TYPES.HARD, event)}
              aria-label='hard-delete-button'
            >
              Hard Delete
            </LoadingButton>
            <LoadingButton
              loading={isLoading.soft}
              disabled={isLoading.hard}
              data-testid='softDeleteButton'
              onClick={(event) => handleDelete(DELETE_TYPES.SOFT, event)}
              autoFocus
              aria-label='soft-delete-button'
            >
              Soft Delete
            </LoadingButton>
          </Box>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
