import { useState, useEffect, useMemo, createContext } from "react";
import { useAccount } from "@azure/msal-react";
import {
  readDataFromLocalStorage,
  generateEntityKeyForLocalStorage,
} from "./utils/table";

const GlobalContext = createContext();

// This provider is intended to be surrounding the whole application.
// It should receive the users permissions as parameter
const GlobalContextProvider = ({ children }) => {
  const [user, setUser] = useState({
    name: "Anonymous User",
    username: "Anonymous",
  });

  const [globalFilters, setGlobalFilters] = useState(() => {
    const cachedGlobalFilter = readDataFromLocalStorage(
      generateEntityKeyForLocalStorage("object", "global")
    );
    return cachedGlobalFilter || null;
  });

  // Get the account information from the useAccount hook
  // @docs: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md#useaccount-hook
  const accountInfo = useAccount();

  useEffect(() => {
    if (accountInfo) {
      setUser({
        name: accountInfo.name ?? "",
        username: accountInfo.username ?? "",
      });
    }
  }, [accountInfo]);

  const contextValue = useMemo(
    () => ({
      user,
      isAuthenticated: accountInfo !== null,
      globalFilters,
      setGlobalFilters,
    }),
    [user, accountInfo, globalFilters]
  );

  // This component will render its children
  // wrapped around a UserContext's provider whose
  // value is set to the method defined above
  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContextProvider, GlobalContext };
