import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://e49e1f02595b449f8fa85fb7c5d5053d@o230677.ingest.us.sentry.io/6075430",
  integrations: [Sentry.browserTracingIntegration()],
  environment: process.env.NODE_ENV,
  enabled:
    process.env.NODE_ENV === "production" ||
    window.location.href.indexOf("bs-frontend.pages.dev") === -1,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^https:\/\/e-oasis-api\.de-c1\.cloudhub\.io\//],
});
