import "./lib/sentry";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './version';
import msalInstance from './msalInstance';

window.version();

ReactDOM.render(
    <React.StrictMode>
      <App pca={msalInstance} />
    </React.StrictMode>,
    document.getElementById('root'),
);
