import { useContext } from "react";
import { Box } from "@mui/system";
import { GlobalContext } from "../../globalContext";
import GlobalFilterSelect from "./../filters/GlobalFilterSelect";
import {
  saveDataToLocalStorage,
  generateEntityKeyForLocalStorage,
} from "../../utils/table";

export const GlobalFiltersContainer = () => {
  const { globalFilters, setGlobalFilters } = useContext(GlobalContext);

  const handleFieldChange = (e, value) => {
    const globalFilter = {
      columnField: "object_id",
      operatorValue: "contains",
      value: value === null ? "" : value,
      init: false,
    };

    setGlobalFilters(globalFilter);
    saveDataToLocalStorage(
      generateEntityKeyForLocalStorage("object", "global"),
      globalFilter
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: 1,
        paddingRight: "1rem",
        height: "100%",
      }}
      data-testid='global-filters-container'
    >
      <GlobalFilterSelect
        onChange={handleFieldChange}
        id='globalObjectFilter'
        variant='outlined'
        label='Currently working on'
        size='small'
        data-testid='global-filter-field'
        value={globalFilters?.value || null}
        sx={{
          height: "100%",
        }}
        inputProps={{
          sx: {
            height: "100%",

            "& .MuiInputBase-root": {
              width: 300,
              height: "100%",
              color: "#FFFFFF",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#FFFFFF !important",
              color: "#FFFFFF",
            },
            "& .MuiInputLabel-root": {
              top: "50%",
              transform: "translate(14px, -50%) scale(1)",
              color: "#FFFFFF",
              lineHeight: 1,
            },
            "& .MuiFormLabel-filled": {
              transform: "translate(14px, -5px) scale(0.75)",
              top: 0,
            },
            "& .MuiInputLabel-root.Mui-focussed": {
              color: "#FFFFFF",
              lineHeight: 1,
            },
            "& .MuiAutocomplete-endAdornment svg": {
              fill: "#FFFFFF",
            },
          },
        }}
      />
    </Box>
  );
};
