import { noop } from "../utils/utils";

/**
 * curried function that is used by fields
 * to
 * - call setValueCallback with data
 * - call onChangeCallBack with the event
 * @param {setValueCallback} cb - the callback that sets the state
 * @param {onChangeCallBack} cb - the callback for onChange
 * @returns {}
 */
export function createChangeHandler(
  setValueCallback = noop.fn,
  onChangeCallBack = noop.fn
) {
  return (e) => {
    // guard
    if ("target" in e === false) {
      return;
    }
    const { value, type, fieldname } = e.target;

    const sanitizedValue = sanitize(value, type);

    setValueCallback({ value: sanitizedValue, fieldname: fieldname });
    if (onChangeCallBack) {
      return onChangeCallBack(e);
    }
  };
}

/**
 *
 * @param {number|string|boolean} value
 * @param {string} type
 * @returns {number|string|boolean} the sanitized value
 */
export function sanitize(value, type) {
  // settings
  const SUPPORTED_TYPES = ["number"];
  // guard
  if (SUPPORTED_TYPES.includes(type)) {
    return value === "" ? null : Number(value);
  }
  return value;
}

/**
 * High order function withConfig
 * that takes a config object and loads it into the component
 * @param {object} config
 * @returns {function} a function that takes a component
 */
export function withConfig(config) {
  // guard
  if (typeof config !== "object") {
    throw new Error("config must be an object");
  }
  return (Component) => {
    return (props) => {
      return <Component {...props} {...config} />;
    };
  };
}

/**
 * Formats a label string with additional annotations based on the provided attributes.
 * @param {Object} data - An object containing the label, required flag, and unit.
 * @returns {string} The formatted label.
 */
export function formatLabel(data) {
  if (!data || typeof data !== "object") {
    return "";
  }
  const { label, required, unit } = data;
  if (!label) {
    return "";
  }
  let formattedLabel = label;
  if (required) {
    formattedLabel += " (required)";
  }
  if (unit) {
    formattedLabel += ` [${unit}]`;
  }
  return formattedLabel;
}

/**
 * Includes the initial value in the fetched data if it's not already present.
 *
 * @param {Object} params - The parameters object.
 * @param {Array} params.fetchedData - The array of data fetched from the API.
 * @param {Object} params.initialValue - The initial value to include.
 * @param {string} params.identifier - The primary key used for matching items (e.g., "id").
 * @param {string} [params.initialValueIdentifier] - An optional identifier specific to the initial value (e.g., "section_id").
 * @param {string} [params.secondaryFieldNameToBeSet] - An optional secondary field name used for comparison (e.g., "source_section_id").
 * @returns {Array} The updated fetched data array, potentially with the initial value prepended.
 */
export const includeInitialValue = ({
  fetchedData,
  initialValue,
  identifier,
  initialValueIdentifier,
  secondaryFieldNameToBeSet,
}) => {
  // Guard against undefined or invalid 'fetchedData'
  if (!Array.isArray(fetchedData)) {
    fetchedData = [];
  }

  // Guard against undefined 'initialValue'
  if (!initialValue) {
    return fetchedData;
  }

  // Guard against undefined 'identifier' and 'initialValueIdentifier'
  if (!identifier && !initialValueIdentifier) {
    return fetchedData;
  }

  // Determine the key to use for comparison in fetchedData
  const keyToCompare = initialValueIdentifier || identifier;

  // Determine the value to compare from the initial value:
  // Use 'initialValueIdentifier' if provided; otherwise, use 'identifier'.
  let initialValueKey;
  if (secondaryFieldNameToBeSet) {
    const fieldValue = initialValue[secondaryFieldNameToBeSet];
    if (Array.isArray(fieldValue)) {
      // Handle the case where the field value is an array (e.g., [sectionId, nestedSectionId]).
      // For example, in a cable entity's source_section_id, which is an array of [section_id, nested_section_id].
      initialValueKey = fieldValue[0];
    } else {
      // Handle the case where the field value is a single value (e.g., a string or number).
      // For example, in an equipment entity's section, where section_id is a single value.
      initialValueKey = fieldValue;
    }
  } else {
    // Use 'initialValueIdentifier' or 'identifier' to get the key from 'initialValue'
    const keyInInitialValue = initialValueIdentifier || identifier;
    initialValueKey = initialValue[keyInInitialValue];
  }

  // Guard against undefined 'initialValueKey'
  if (initialValueKey === undefined) {
    return fetchedData;
  }

  // Check if the initial value is already included in the fetched data:
  // - Look for a match between the 'keyToCompare' and the 'initialValueKey'.
  const initialValueExists = fetchedData.some(
    (responseItem) => responseItem[keyToCompare] === initialValueKey
  );

  // If the initial value is not found in the fetched data:
  // Prepend the initial value to the fetched data array.
  if (!initialValueExists) {
    fetchedData = [initialValue, ...fetchedData];
  }

  return fetchedData;
};
