export const getNameFromValue = (options, value) => {
  if (!value || value === "") return "";
  const obj = options?.find((o) => o.abbr === (value?.abbr || value));
  return `${obj?.description} (${obj?.abbr})`;
};

export const isSameValue = (option, value) => {
  return value === option.abbr;
};

/**
 * Utility function to get a friendlier error message from a string.
 * @param {String} errorMsg
 * @returns {String} a friendlier error message
 */
export const getFriendlyMessage = (errorMsg = "") => {
  const friendlyMessagesMap = {
    duplicateKey: "Record already exists.",
    serverError:
      "There was a problem with the request. Please try different input.",
    deleted:
      "The record(s) that you are trying to change have been (soft) deleted.",
  };
  switch (true) {
    case /do not exist/i.test(errorMsg):
      return friendlyMessagesMap.deleted;
    case /bad request/i.test(errorMsg):
    case /internal server error/i.test(errorMsg):
      return friendlyMessagesMap.serverError;
    case /duplicatekey/i.test(errorMsg):
      return friendlyMessagesMap.duplicateKey;
    default:
      return errorMsg;
  }
};
