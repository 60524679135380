// Disabled because it doesn't improve readability
/* eslint-disable max-len */
import React, { useEffect } from "react";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./setting";
import { Switch, Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import { UserPermissionProvider } from "./userPermissionContext";
import { GlobalContextProvider } from "./globalContext";

import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import { LicenseInfo } from "@mui/x-data-grid-pro";

import EntityOverview from "./views/EntityOverview";
import ObjectDetail from "./views/ObjectDetail";
import ProjectDetail from "./views/ProjectDetail";
import SystemGroupDetail from "./views/SystemGroupDetail";
import SystemDetail from "./views/SystemDetail";
import LocationDetail from "./views/LocationDetail";
import EquipmentDetail from "./views/EquipmentDetail";
import SectionDetail from "./views/SectionDetail";
import CableDetail from "./views/CableDetail";
import CableTypeDetail from "./views/CableTypeDetail";
import RoutePointDetail from "./views/RoutePointDetail";
import CableBundleDetail from "./views/CableBundleDetail";
import DrumDetail from "./views/DrumDetail";
import OrderDetail from "./views/OrderDetail";
import HomeDashboard from "./views/HomeDashboard";
import Export from "./views/Export";

import Layout from "./components/Layout";
import { FeedbackComponent } from "./components/FeedbackComponent";

import ReactGA from "react-ga";
import reportWebVitals from "./reportWebVitals";

import { FILTER_TYPES } from "./dao/types";
import PrivateRoute from "./PrivateRoute";
import { Unauthorised } from "./views/Unauthorised";
import EditModes from "./enums/EditModes";
import { ROUTES } from "./enums/Routes";
import { FeedbackProvider } from "./feedbackContext";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
  testMode: process.env.NODE_ENV === "test",
});

/**
 * MUI License Key Installation
 * Do not remove this line as we are using a paid license
 * for @mui/x-data-grid-pro
 */

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);

const history = createBrowserHistory();

/**
 * Hit GA for a page-view
 */
function trackPageView(page) {
  ReactGA.set({ page });
  ReactGA.pageview(page);

  reportWebVitals(({ id, name, value }) =>
    ReactGA.event({
      action: name,
      category: "Web Vitals",
      label: id,
      nonInteraction: true,
      value: Math.round(name === "CLS" ? value * 1000 : value),
    })
  );
}

function App({ pca }) {
  useEffect(() => {
    const page = history.location.pathname;
    // on mount
    trackPageView(page);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <MsalProvider instance={pca}>
        <GlobalContextProvider>
          <Router history={history}>
            <FeedbackProvider>
              <UserPermissionProvider>
                <Layout>
                  <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                  >
                    <Switch>
                      <Route path='/export'>
                        <Export />
                      </Route>
                      <Route path={ROUTES.order.edit}>
                        <PrivateRoute entity={FILTER_TYPES.route_point}>
                          <OrderDetail
                            title='Order details'
                            key={EditModes.UPDATE}
                            mode={EditModes.UPDATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.order.create}>
                        <PrivateRoute entity={FILTER_TYPES.order}>
                          <OrderDetail
                            title='Create Order'
                            key={EditModes.CREATE}
                            mode={EditModes.CREATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.drums.edit}>
                        <PrivateRoute entity={FILTER_TYPES.drum}>
                          <DrumDetail
                            title='Drum details'
                            key={EditModes.UPDATE}
                            mode={EditModes.UPDATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.drums.create}>
                        <PrivateRoute entity={FILTER_TYPES.drum}>
                          <DrumDetail
                            title='Create Drum'
                            key={EditModes.CREATE}
                            mode={EditModes.CREATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.routePoints.edit}>
                        <PrivateRoute entity={FILTER_TYPES.route_point}>
                          <RoutePointDetail
                            title='Route point details'
                            key={EditModes.UPDATE}
                            mode={EditModes.UPDATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.routePoints.create}>
                        <PrivateRoute entity={FILTER_TYPES.route_point}>
                          <RoutePointDetail
                            title='Create Route point'
                            key={EditModes.CREATE}
                            mode={EditModes.CREATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.bundle.edit}>
                        <PrivateRoute entity={FILTER_TYPES.cable_bundle}>
                          <CableBundleDetail
                            title='Bundle details'
                            key={EditModes.UPDATE}
                            mode={EditModes.UPDATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.bundle.create}>
                        <PrivateRoute entity={FILTER_TYPES.cable_bundle}>
                          <CableBundleDetail
                            title='Create Bundle'
                            key={EditModes.CREATE}
                            mode={EditModes.CREATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.cableType.edit}>
                        <PrivateRoute entity={FILTER_TYPES.cable_type}>
                          <CableTypeDetail
                            title='Cable types details'
                            key={EditModes.UPDATE}
                            mode={EditModes.UPDATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.cableType.create}>
                        <PrivateRoute entity={FILTER_TYPES.cable_type}>
                          <CableTypeDetail
                            title='Create Cable type'
                            key={EditModes.CREATE}
                            mode={EditModes.CREATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.cable.edit}>
                        <PrivateRoute entity={FILTER_TYPES.cable}>
                          <CableDetail
                            title='Cable details'
                            key={EditModes.UPDATE}
                            mode={EditModes.UPDATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.cable.create}>
                        <PrivateRoute entity={FILTER_TYPES.cable}>
                          <CableDetail
                            title='Create Cable'
                            key={EditModes.CREATE}
                            mode={EditModes.CREATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.section.edit}>
                        <PrivateRoute entity={FILTER_TYPES.section}>
                          <SectionDetail
                            title='Section details'
                            key={EditModes.UPDATE}
                            mode={EditModes.UPDATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.section.create}>
                        <PrivateRoute entity={FILTER_TYPES.section}>
                          <SectionDetail
                            title='Create Section'
                            key={EditModes.CREATE}
                            mode={EditModes.CREATE}
                          />
                        </PrivateRoute>
                      </Route>
                      {/* Without locationId or sectionId */}
                      <Route path={ROUTES.equipment.edit}>
                        <PrivateRoute entity={FILTER_TYPES.equipment}>
                          <EquipmentDetail
                            title='Equipment details'
                            key={EditModes.UPDATE}
                            mode={EditModes.UPDATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.equipment.create}>
                        <PrivateRoute entity={FILTER_TYPES.equipment}>
                          <EquipmentDetail
                            title='Create Equipment'
                            key={EditModes.CREATE}
                            mode={EditModes.CREATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.location.edit}>
                        <PrivateRoute entity={FILTER_TYPES.location}>
                          <LocationDetail
                            title='Location details'
                            key={EditModes.UPDATE}
                            mode={EditModes.UPDATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.location.create}>
                        <PrivateRoute entity={FILTER_TYPES.location}>
                          <LocationDetail
                            title='Create Location'
                            key={EditModes.CREATE}
                            mode={EditModes.CREATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.system.edit}>
                        <PrivateRoute entity={FILTER_TYPES.system}>
                          <SystemDetail
                            title='System details'
                            key={EditModes.UPDATE}
                            mode={EditModes.UPDATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.system.create}>
                        <PrivateRoute entity={FILTER_TYPES.system}>
                          <SystemDetail
                            title='Create System'
                            key={EditModes.CREATE}
                            mode={EditModes.CREATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.systemGroup.edit}>
                        <PrivateRoute entity={FILTER_TYPES.system_group}>
                          <SystemGroupDetail
                            title='System group details'
                            key={EditModes.UPDATE}
                            mode={EditModes.UPDATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.systemGroup.create}>
                        <PrivateRoute entity={FILTER_TYPES.system_group}>
                          <SystemGroupDetail
                            title='Create System group'
                            key={EditModes.CREATE}
                            mode={EditModes.CREATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.project.edit}>
                        <PrivateRoute entity={FILTER_TYPES.project}>
                          <ProjectDetail
                            title='Project details'
                            key={EditModes.UPDATE}
                            mode={EditModes.UPDATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.project.create}>
                        <PrivateRoute entity={FILTER_TYPES.project}>
                          <ProjectDetail
                            title='Create Project'
                            key={EditModes.CREATE}
                            mode={EditModes.CREATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.object.edit}>
                        <PrivateRoute entity={FILTER_TYPES.object}>
                          <ObjectDetail
                            title='Object details'
                            key={EditModes.UPDATE}
                            mode={EditModes.UPDATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.object.create}>
                        <PrivateRoute entity={FILTER_TYPES.object}>
                          <ObjectDetail
                            title='Create Object'
                            key={EditModes.CREATE}
                            mode={EditModes.CREATE}
                          />
                        </PrivateRoute>
                      </Route>
                      <Route path={ROUTES.order.list}>
                        <EntityOverview
                          entity={FILTER_TYPES.order}
                          title={"Orders overview"}
                        />
                      </Route>
                      <Route path={ROUTES.drums.list}>
                        <EntityOverview
                          entity={FILTER_TYPES.drum}
                          title={"Drums overview"}
                        />
                      </Route>
                      <Route path={ROUTES.routePoints.list}>
                        <EntityOverview
                          entity={FILTER_TYPES.route_point}
                          title={"Route points overview"}
                        />
                      </Route>
                      <Route path={ROUTES.bundle.list}>
                        <EntityOverview
                          entity={FILTER_TYPES.cable_bundle}
                          title={"Bundles overview"}
                        />
                      </Route>
                      <Route path={ROUTES.cableType.list}>
                        <EntityOverview
                          entity={FILTER_TYPES.cable_type}
                          title={"Cable types overview"}
                        />
                      </Route>
                      <Route path={ROUTES.cable.list}>
                        <EntityOverview
                          entity={FILTER_TYPES.cable}
                          title={"Cables overview"}
                        />
                      </Route>
                      <Route path={ROUTES.equipment.list}>
                        <EntityOverview
                          entity={FILTER_TYPES.equipment}
                          title={"Equipments overview"}
                        />
                      </Route>
                      <Route path={ROUTES.location.list}>
                        <EntityOverview
                          entity={FILTER_TYPES.location}
                          title={"Locations overview"}
                        />
                      </Route>
                      <Route path={ROUTES.system.list}>
                        <EntityOverview
                          entity={FILTER_TYPES.system}
                          title={"Systems overview"}
                        />
                      </Route>
                      <Route path={ROUTES.systemGroup.list}>
                        <EntityOverview
                          entity={FILTER_TYPES.system_group}
                          title={"System groups overview"}
                        />
                      </Route>
                      <Route path={ROUTES.project.list}>
                        <EntityOverview
                          entity={FILTER_TYPES.project}
                          title={"Projects overview"}
                        />
                      </Route>
                      <Route path={ROUTES.object.list}>
                        <EntityOverview
                          entity={FILTER_TYPES.object}
                          title={"Objects overview"}
                        />
                      </Route>
                      <Route path={ROUTES.section.list}>
                        <EntityOverview
                          entity={FILTER_TYPES.section}
                          title={"Sections overview"}
                        />
                      </Route>
                      <Route path='/unauthorised'>
                        <Unauthorised />
                      </Route>
                      <Route path='/'>
                        <HomeDashboard />
                      </Route>
                    </Switch>
                  </MsalAuthenticationTemplate>
                  <FeedbackComponent />
                </Layout>
              </UserPermissionProvider>
            </FeedbackProvider>
          </Router>
        </GlobalContextProvider>
      </MsalProvider>
    </ThemeProvider>
  );
}

export default App;
