import { useContext, useState } from "react";
import {
  Box,
  Menu,
  MenuItem,
  Alert,
  CircularProgress,
  IconButton,
} from "@mui/material";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import { useReportTypes } from "../hooks/useReportTypes";
import { sendPowerAutomateRequest } from "../dao/report";
import FeedbackContext from "../feedbackContext";
import { AUTOHIDEDURATION_ERROR, SEVERITY } from "./FeedbackComponent";

export const ReportDropdown = ({ selectedRows = [], entity }) => {
  const { reportTypes, error } = useReportTypes(entity);

  const { setFeedback } = useContext(FeedbackContext);

  const [isPowerAutomateRequestLoading, setIsPowerAutomateRequestLoading] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnReportItemClick = async (type) => {
    setIsPowerAutomateRequestLoading(true);
    handleClose();
    const powerAutomatePayload = {
      ids: selectedRows.map((row) => row.id),
      entity,
      report_name: type.display_name,
    };

    try {
      const response = await sendPowerAutomateRequest(powerAutomatePayload);

      // if the response has the reportLink field
      // possible values are: null, undefined, empty string, or a string
      if (response.reportLink) {
        // open the reportLink in a new browser tab
        window.open(response.reportLink, "_blank");
      }
      // use AUTOHIDEDURATION_ERROR for a longer delay (not really an error)
      setFeedback({
        isOpen: true,
        messages: [
          response.message ?? "There is a report available for download.",
        ],
        severity: SEVERITY.INFO,
        autoHideDuration: AUTOHIDEDURATION_ERROR,
      });
    } catch (error) {
      setFeedback({
        isOpen: true,
        messages: [
          error.message ?? "An error occurred while retrieving the report.",
        ],
        severity: SEVERITY.ERROR,
      });
    } finally {
      setIsPowerAutomateRequestLoading(false);
    }
  };

  const menuContent = () => {
    if (error || !reportTypes) {
      return (
        <MenuItem onClick={handleClose} sx={{ width: "50%" }}>
          <Alert severity='error'>
            An error happened while getting the report options. Please refresh
            the page.
          </Alert>
        </MenuItem>
      );
    }
    if (reportTypes.length === 0) {
      return <MenuItem onClick={handleClose}>No option is available.</MenuItem>;
    }
    return reportTypes.map((type) => (
      <MenuItem key={type.id} onClick={() => handleOnReportItemClick(type)}>
        {type.display_name}
      </MenuItem>
    ));
  };

  return (
    <Box sx={{ alignItems: "center", display: "flex", px: 1 }}>
      <IconButton
        id='report-button'
        aria-controls={open ? "report-menu" : null}
        aria-haspopup='true'
        aria-expanded={open ? "true" : null}
        onClick={handleClick}
        disabled={selectedRows.length === 0 || isPowerAutomateRequestLoading}
        sx={{ padding: 0 }}
        title={selectedRows.length === 0 ? "First select row(s)" : "Report"}
        type='button'
      >
        {isPowerAutomateRequestLoading ? (
          <CircularProgress size={18} />
        ) : (
          <AssignmentTurnedInOutlinedIcon />
        )}
      </IconButton>

      <Menu
        id='report-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "report-button",
        }}
      >
        {menuContent()}
      </Menu>
    </Box>
  );
};
