import { useContext, useEffect, useState } from "react";
import { Box } from "@mui/system";

import {
  mapDefaultValues,
  getDefaultFields,
  setKnownValues,
} from "../form/FormFieldParser";
import FormFactory from "../form/FormFactory";

import ButtonWithSpinner from "../components/ButtonWithSpinner";
import NavigateEntity from "../components/NavigateEntity";

import ContentActions from "./ContentActions";
import DisplayEditedRecord from "./DisplayEditedRecord";
import { FormContextProvider } from "../formContext";
import { useFormContext } from "../hooks/useFormContext";
import { sleep } from "../utils/sleep";
import { getEntityReference, getUpdatedFields, noop } from "../utils/utils";
import { useFormState } from "react-use-form-state";
import useUserPermissionsContext from "../hooks/useUserPermissionsContext";
import { updateFieldsWithValidation } from "../form/field/updateFieldsWithValidation";
import { useLocation } from "react-router-dom";
import FeedbackContext from "../feedbackContext";
import { SEVERITY } from "./FeedbackComponent";

const FORM_ACTION = {
  SAVE: "save",
  DELETE: "delete",
  CANCEL: "cancel",
  RESTORE: "restore",
};

function alwaysTrue() {
  return true;
}
function returnArray(array) {
  return [array];
}

function WrappedEditEntity({
  isAllowedToUpdate,
  isAllowedToDelete,
  isAllowedToCreate,
  entity,
  fields,
  storeEntity,
  deleteEntity,
  cancel,
  restoreEntity,
  entityType,
  isUpdateMode,
}) {
  const {
    formEntity,
    setFormEntity,
    setObjectId,
    setSystemGroupId,
    setSystemId,
    setLocationId,
    objectEntity,
    projectEntity,
    systemGroupEntity,
    systemEntity,
    locationEntity,
    equipmentEntity,
    sectionEntity,
    setFormErrors,
    cableBundleEntity,
  } = useFormContext();

  const ENTITY_MESSAGE_TO_APPEND =
    entity?.name ?? "#".concat(entity?.number ?? entity?.id);
  const { setFeedback } = useContext(FeedbackContext);

  // Initialize form state with default values
  const [formState, formStateHandlers] = useFormState(
    !isUpdateMode && mapDefaultValues(fields, { name: "guest" })
  );

  const [saveInProgress, setSaveInProgress] = useState(false);
  const [cancelInProgress, setCancelInProgress] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [fieldsConfig, setFieldsConfig] = useState([...fields]);
  const [emptyRequiredFields, setEmptyRequiredFields] = useState([]);

  // `triggerFetch` is a numeric state variable used to signal updates to child components.
  // It increments whenever an action (like saving) occurs that requires dependent components
  // to refresh their data or re-run their effects. This ensures data consistency.
  const [triggerFetch, setTriggerFetch] = useState(0);

  const authorisedToSave = isAllowedToUpdate || isAllowedToCreate;
  const isMarkedAsDeleted = Boolean(entity?.deleted);

  const { authorisation } = useUserPermissionsContext();

  const installationLock = formState.values?.installation_lock;
  const engineeringLock = formState.values?.engineering_lock;
  useEffect(() => {
    // Below logic is used to disable fields based on the role and lock only for cable entity
    if (entityType !== "cable") return;

    const updatedFields = fieldsConfig.map((field) =>
      getUpdatedFields(field, formState, authorisation)
    );
    setFieldsConfig(updatedFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationLock, engineeringLock]);

  // Write the entity id to the session storage to be used in the back button
  // for scrolling to the correct position in overview page
  const location = useLocation();

  useEffect(() => {
    sessionStorage.setItem("previousEntityId", location.state?.from || "");
  }, [location]);

  async function cancelAction() {
    setCancelInProgress(true);
    cancel().finally(() => {
      setCancelInProgress(false);
    });
  }

  async function saveAction() {
    setFormErrors(null);
    // changedValues - an object with the values that have changed or not empty in the form
    // based on the formState.values and the formState.pristine flag
    const changedValues = Object.keys(formState.values).reduce((acc, key) => {
      if (formState.pristine[key] === false || !!formState.values[key]) {
        acc[key] = formState.values[key];
      }
      return acc;
    }, {});

    // If no values have changed, return early
    // and clear all required fields error if happened before saving
    if (Object.keys(changedValues).length === 0) {
      setFeedback({
        severity: SEVERITY.WARNING,
        messages: ["No fields were updated!"],
        isOpen: true,
      });
      const updatedFields = updateFieldsWithValidation(fieldsConfig);
      setFieldsConfig(updatedFields);
      return false;
    }

    // If there are empty required fields, return early
    if (emptyRequiredFields.length > 0) {
      const updatedFields = updateFieldsWithValidation(
        fieldsConfig,
        emptyRequiredFields
      );
      setFieldsConfig(updatedFields);
      setFeedback({
        messages: ["There are empty required fields!"],
        isOpen: true,
        severity: SEVERITY.WARNING,
      });
      return false;
    }

    setFeedback({ isOpen: false });
    setSaveInProgress(true);

    await sleep(1000);
    storeEntity(changedValues)
      .then(() => {
        setFeedback({
          messages: [`We've updated ${ENTITY_MESSAGE_TO_APPEND}!`],
          isOpen: true,
          severity: SEVERITY.SUCCESS,
        });
        // clear all isFieldFailed, since the form is saved successfully
        const updatedFields = updateFieldsWithValidation(fieldsConfig);
        setFieldsConfig(updatedFields);
        setTriggerFetch((prev) => prev + 1);
      })
      .catch((error) => {
        // Highlight fields that failed to be submitted
        const failedFields =
          error.response?.body?.errors.map((e) => e.field) || [];
        const updatedFields = updateFieldsWithValidation(
          fieldsConfig,
          failedFields
        );
        setFieldsConfig(updatedFields);

        // If a form fails to be submitted because of some values
        // store the values as backup in order to reuse the correct
        // values on the next attempt
        setFormEntity(entity);
        setFeedback({
          messages:
            error.response?.body?.errors.map((e) => {
              const fieldMessage = e.field ? `${e.field}: ` : ""; // Check if field exists and prepend it
              return `${fieldMessage}${e.message}`;
            }) || [],

          isOpen: true,
          severity: SEVERITY.ERROR,
        });
      })
      .finally(() => {
        setSaveInProgress(false);
      });
  }

  async function deleteAction(action) {
    const entityNumber = getEntityReference(entity);
    const deleteConsent = window.confirm(
      `Do you want to ${action} ${entityNumber}?`
    );
    if (deleteConsent === true) {
      setDeleteInProgress(true);
      try {
        if (action === FORM_ACTION.DELETE) {
          await deleteEntity(entity);
          setFeedback({
            messages: [`We've deleted ${ENTITY_MESSAGE_TO_APPEND}!`],
            isOpen: true,
            severity: SEVERITY.WARNING,
          });
        } else {
          await restoreEntity(entity);
          setFeedback({
            messages: [`We've restored ${ENTITY_MESSAGE_TO_APPEND}!`],
            isOpen: true,
            severity: SEVERITY.SUCCESS,
          });
        }
      } catch (error) {
        setFeedback({
          messages: error.response?.body?.errors.map((e) => e.message) || [],
          isOpen: true,
          severity: SEVERITY.ERROR,
        });
      }
      setDeleteInProgress(false);
    }
  }

  const formFactoryProps = {
    values: entity,
    entityType: entityType,
    fields: fieldsConfig,
    setKnownValues,
    formState,
    formStateHandlers,
    onDebounce: async (name, value, validity) => {
      const FIELD_DEPENDENCIES = {
        system: {
          object_id: ["system_group_id"],
        },
        equipment: {
          object_id: [
            "system_group_id",
            "system_id",
            "project_id",
            "location_id",
            "section_reference",
            "supplied_from_reference",
          ],
          system_group_id: ["system_id"],
        },
        section: {
          object_id: [
            "system_group_id",
            "system_id",
            "equipment_id",
            "supplied_from_1",
            "supplied_from_2",
            "supplied_from_3",
          ],
          system_group_id: ["system_id", "equipment_id"],
          system_id: ["equipment_id"],
        },
        cable: {
          object_id: [
            "system_group_id",
            "system_id",
            "project_id",
            "source_section_reference",
            "destination_section_reference",
          ],
          system_group_id: ["system_id"],
        },
      };

      const entityFieldConfig = FIELD_DEPENDENCIES[entityType] || {};
      const fieldDependencyConfig = entityFieldConfig[name] || [];

      // use the back value to fill the new empty object at "view" level
      if (Object.keys(formEntity).length > 0) {
        Object.keys(formEntity).forEach((key) => {
          entity[key] = formEntity[key];
        });
        // clear formEntity
        setFormEntity({});
      }
      // current value
      const currentFieldValue = entity[name];

      if (validity) {
        // compare field value with that in state (props)
        // if not the same (changed)
        if (currentFieldValue !== value) {
          // then - set new value
          entity[name] = value;
          // then - clear associated values
          fieldDependencyConfig.forEach((key) => {
            entity[key] = null;
          });
        }
      }
      if (entity.object_id) {
        // This is used to make available
        // for the hook useEntities for system groups
        // the object selected through the context
        setObjectId(entity.object_id);
      }
      if (entity.system_group_id) {
        setSystemGroupId(entity.system_group_id);
      }
      if (entity.system_id) {
        setSystemId(entity.system_id);
      }
      if (entity.location_id) {
        setLocationId(entity.location_id);
      }
      return true;
    },
    sortFields: returnArray,
    getDefaultFields,
    isVisible: alwaysTrue,
    sortingFilter: alwaysTrue,
    setDefaultFields: () => noop.fn,
    setVisibleFields: () => Promise.resolve(true),
    copyAll: () => noop.fn,
    copyAllInProgress: false,
    copyOneValue: () => noop.fn,
    setEmptyRequiredFields,
    triggerFetch,
  };

  return (
    <>
      {isUpdateMode && (
        <NavigateEntity
          entityType={entityType}
          entity={entity}
          objectEntity={objectEntity}
          projectEntity={projectEntity}
          systemGroupEntity={systemGroupEntity}
          systemEntity={systemEntity}
          locationEntity={locationEntity}
          equipmentEntity={equipmentEntity}
          sectionEntity={sectionEntity}
          cableBundleEntity={cableBundleEntity}
        />
      )}
      <FormFactory {...formFactoryProps} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <DisplayEditedRecord type={entityType} {...{ entity }} />
        <ContentActions>
          <ButtonWithSpinner
            onClick={cancelAction}
            loading={cancelInProgress}
            disabled={cancelInProgress}
            variant='text'
            color='primary'
            title={"Cancel"}
            sx={{
              marginLeft: 1,
            }}
          >
            Cancel
          </ButtonWithSpinner>
          <ButtonWithSpinner
            onClick={saveAction}
            loading={saveInProgress}
            disabled={saveInProgress || !authorisedToSave || isMarkedAsDeleted}
            variant='contained'
            color='primary'
            sx={{
              marginLeft: 1,
            }}
            title={
              !authorisedToSave || isMarkedAsDeleted
                ? "You are not allowed to perform this action"
                : undefined
            }
          >
            Save
          </ButtonWithSpinner>
          {deleteEntity && isUpdateMode && !isMarkedAsDeleted && (
            <ButtonWithSpinner
              onClick={() => {
                deleteAction(FORM_ACTION.DELETE);
              }}
              disabled={deleteInProgress || !isAllowedToDelete}
              loading={deleteInProgress}
              variant='contained'
              color='error'
              sx={{
                marginLeft: 1,
              }}
              title={
                !isAllowedToDelete
                  ? "You are not allowed to perform this action"
                  : undefined
              }
            >
              Delete
            </ButtonWithSpinner>
          )}
          {isMarkedAsDeleted && (
            <ButtonWithSpinner
              onClick={() => {
                deleteAction(FORM_ACTION.RESTORE);
              }}
              disabled={deleteInProgress || !isAllowedToDelete}
              loading={deleteInProgress}
              variant='text'
              color='error'
              sx={{
                marginLeft: 1,
              }}
              title={
                !isAllowedToDelete
                  ? "You are not allowed to perform this action"
                  : undefined
              }
            >
              Restore
            </ButtonWithSpinner>
          )}
        </ContentActions>
      </Box>
    </>
  );
}

export default function EditEntity(props) {
  return (
    <FormContextProvider>
      <WrappedEditEntity {...props} />
    </FormContextProvider>
  );
}
